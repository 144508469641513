<template>
    <div>
        <ScrollPanel style="width: 100%; height: 600px">
            <div class="p-grid">
                <DataTable :value="references" :filters="filters" scrollable :paginator="true" dataKey="id"
                           paginatorPosition="bottom" :rows="page.per_page" :lazy="true" :totalRecords="page.total"
                           :loading="loading"  @page="onPage($event)" class="content">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left>
                                <h5 class="p-mr-2">
                                    Referencias bancarias
                                </h5>
                            </template>                            <template #right>
                                <Button label="Nuevo registro" icon="pi pi-plus" class="p-button-success p-mr-2"
                                        @click="newReference()" />
                            </template>
                        </Toolbar>
                    </template>

                    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :rowClass="col.class"></Column>
                    <Column header="Opciones"  headerStyle="width: 200px">
                        <template #body="slotProps">
                            <Button v-tooltip="'Editar'" icon="pi pi-pencil" class="p-button-rounded p-button-success  p-mr-2" @click="edit(slotProps.data)"/>
                            <Button  v-tooltip="'Eliminar'" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteReference($event, slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </ScrollPanel>
        <Dialog v-model:visible="editDialog" :style="{width: '900px'}" header="Datos de referencia" :modal="true"
                class="p-fluid">

            <div class="p-grid">
                <div class="p-col-12 p-md-6">
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="ced" type="text" v-model="data.bank"
                                        :class="{'p-invalid': submitted && !data.bank }" class="p-inputtext-sm"></input-text>
                            <label for="ced">Banco</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.bank">El banco es requerido</small>
                    </div>
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="name" type="text" v-model="data.account_number"
                                        :class="{'p-invalid': submitted && !data.account_number }" class="p-inputtext-sm"/>
                            <label for="name">Número de cuenta</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.account_number">El número de cuenta es requerido</small>
                    </div>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="p-field p-fluid">
                        <span class="p-float-label">
                            <Dropdown id="account_type" v-model="data.account_type" :options="catalog.accountType" :show-clear="true"
                                      :class="{'p-invalid': submitted && !data.account_type }"/>
                            <label for="account_type">Tipo de cuenta</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.account_type">El tipo de cuenta es requerido</small>
                    </div>
                    <div class="p-field p-fluid">
                        <span class="p-float-label">
                            <MultiSelect v-model="data.cards" :options="catalog.cards" optionLabel="name" display="chip"/>
                            <label for="name">Tarjetas de crédito con el banco</label>
                        </span>
                    </div>
                </div>
                </div>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
                <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveReference"/>
            </template>
        </Dialog>
    </div>

</template>

<script>
    import service from '../../service/references.service'
    import dataService from '../../service/data.service'
    import {mapState} from "vuex";

    export default {
        name: "BankReferences",
        props: {
            holder: Object,
        },
        data() {
            return {
                service: service('bank/references'),
                catalog: dataService,
                columns: [
                    {field: 'bank', header: 'Banco'},
                    {field: 'account_number', header: 'Número de cuenta'},
                    {field: 'account_type', header: 'Tipo de cuenta'}
                ],
                page: {
                    page: 1,
                    per_page: 5,
                    total: 0,
                    order: "desc"
                },
                references: [],
                data: {},
                filters: {},
                submitted: false,
                loading: false,
                editDialog: false,
                verifyDataDialog: false
            }
        },
        methods: {
            deleteReference(event, data) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: 'Desea eliminar el registro?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.loading = true;
                        this.service.delete(data.id).then(() => {
                            this.getData();
                        }).catch(err => {
                            const message = err.response.data;
                            let error = this.$utils.formatError(message);
                            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                        }).finally(() => this.loading = false);
                    },
                    reject: () => {
                    }
                });
            },
            edit(val) {
                this.currentItem = val;
                this.currentItem.cards = [];
                Object.keys(this.currentItem).forEach(x => {
                   this.currentItem.cards = [ ... this.currentItem.cards , ... this.catalog.cards.filter(c => c.value === x && this.currentItem[x] === '1')]
                });
                this.currentItem.account_type = this.currentItem.account_type.toLowerCase();
                this.data = this.currentItem;
                this.$nextTick(() => {
                    this.editDialog = true;
                })

            },
            onPage(event) {
                this.page.page = event.page + 1;
                this.getData()
            },
            newReference() {
                this.submitted = false;
                this.currentItem = null;
                this.data =  {};
                this.editDialog = true;
            },
            getData() {
                this.loading = true;
                this.service.getByHolder(this.holder.id, this.page).then(x => {
                    this.references = x.data;
                    this.page.total = this.references.length;
                }).catch(() => this.references = []).finally(() =>   {
                    this.loading = false
                })
            },
            formatData() {
                this.data.holder_id = this.holder.id;
                this.catalog.cards.forEach(x => {
                    const value = this.data.cards.findIndex(c => c.value === x.value);
                    this.data[x.value] = value >= 0 ? "1" : "0";
                })
            },
            saveReference() {
                this.submitted = true;
                if(!this.data.bank || !this.data.account_number || !this.data.account_type) {
                    return;
                }
                this.formatData();
                this.service.save(this.data, this.currentItem?.id).then(() => {
                    this.getData();
                    this.editDialog = false;
                }).catch(err => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                })
            },
        },
        computed: {
            ...mapState('enroll', ['country']),
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped lang="scss">

    .p-field{
        margin-top: 30px !important;
    }
    .content {
        overflow-wrap: break-word;
    }

    ::v-deep .p-dialog-content{
        height: 100%;
        overflow: inherit!important;

    }


</style>
